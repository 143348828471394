import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { MobileWidthCheckerService } from '../../services/mobile-width-checker.service';
import { AdminAuthService } from '@services/admin-auth.service';
import { Router } from '@angular/router';
import { BusinessService } from '@app/services/business.Service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent implements OnInit {
  userId: any;
  userName: any;
  userEmail: any;
  profileDropdown: MenuItem[] = [];
  currentLang: string;
  selectedCountry: string | undefined;
  isMobileScreen$ = this.mobileWidthCheckerService.isMobileScreen$;
  showDropdown = false;
  selectedDropdownItem = { label: '' };
  profile_image_path = '/assets/images/avatar.jpg';
  constructor(
    private router: Router,
    private translate: TranslateService,
    private authService: AdminAuthService,
    private mobileWidthCheckerService: MobileWidthCheckerService,
    private businessService: BusinessService
  ) {
    this.currentLang =
      localStorage.getItem('language') || translate.getDefaultLang() || 'en';
  }
  ngOnInit() {
    this.switchLanguage(this.currentLang);
    const user = JSON.parse(localStorage.getItem('User') || '');
    this.profile_image_path = user.image_path || '/assets/images/avatar.jpg';

    this.userId = user.id;
    this.businessService.GetOne(this.userId, '').subscribe((res: any) => {
      const userData = res.body.data;
      if (userData.firstname && userData.firstname.trim() !== '') {
        this.userName = `${userData.firstname} ${userData.lastname}`;
      } else {
        this.userName = userData.email;
      }
      this.userEmail = userData.email;
    });
  }

  getProfileMenuItems() {
    this.translate.get(['profile', 'logOut']).subscribe((translations) => {
      this.profileDropdown = [
        {
          label: translations['profile'],
          id: 'profile',
          command: () => this.navigateToProfile()
        },
        {
          label: translations['logOut'],
          id: 'logOut',
          command: (event) => this.onMenuItemSelect(event)
        }
      ];
    });
  }

  switchLanguage(language: string) {
    this.currentLang = language;
    this.translate.use(language);
    this.translate.setDefaultLang(language);
    this.getProfileMenuItems()
    localStorage.setItem('language', language);
    const html: HTMLHtmlElement = document.getElementsByTagName('html')[0];
    if (html) {
      const langDir = language === 'ar' ? 'rtl' : 'ltr';
      html.classList.remove('rtl', 'ltr');
      html.dir = langDir;
      html.classList.add(langDir);
    }
  }

  onMenuItemSelect(event: any) {
    if (event.item.label === 'logOut') {
      this.logOut();
    }
  }
  onDropdownChange(event: any) {
    console.log('event profile change', event.value)
    if (event.value.id === 'logOut') {
      this.logOut();
    }
    if (event.value.id === 'profile') {
      this.navigateToProfile()
    }
  }
  navigateToProfile() {
    this.router.navigate(['profile'])
  }
  logOut() {
    this.authService.LogOut().subscribe(config => {
      window.location.reload();
      return this.router.navigate(['/']);
    });
  }
}