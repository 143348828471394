import { Injectable, Injector } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { config } from '@models/Config';
import {
  EMAIL_PATTERN,
  PHONE_PATTERN
} from '../reusable-components/form-fields/validation-errors/validation-patterns-list';
import { environment } from './../../environments/environment';
import { BasicService } from './lib/Basic.Service';
import { Helper } from './lib/Helper.service';

@Injectable()
export class BusinessService extends BasicService<config<any>> {


  baseApiUrl = `${environment.API_URL}businesses`;

  constructor(protected override injector: Injector) {
    super('businesses/', injector);
  }

  addFileds = {
    photo: [''],

    dial_code: ['966', [Validators.required]],
    country_code: ['SA', [Validators.required]],
    email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
    phone: ['', [Validators.required, Validators.pattern(PHONE_PATTERN)]],
    name: ['', [Validators.required]],
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    notes: [''],
    address: [''],
    city: ['', [Validators.required]],
    country: ['', [Validators.required]]
  };

  updateFileds = {
    ...this.addFileds
  };

  getPosBusinesses(id: string): Observable<any> {
    const endpoint = `businesses/${id}/pos_businesses`;
    return this.Get(this.ReturnType, endpoint, '');
  }

  getPosBranches(id: string): Observable<any> {
    const endpoint = `businesses/${id}/pos_branches`;
    return this.Get(this.ReturnType, endpoint, '');
  }

  getPosProductCategory(id: string): Observable<any> {
    const endpoint = `businesses/${id}/pos_categories`;
    return this.Get(this.ReturnType, endpoint, '');
  }

  getPosProducts(id: string): Observable<any> {
    const endpoint = `businesses/${id}/pos_products`;
    return this.Get(this.ReturnType, endpoint, '');
  }

  syncPosBusiness(id: string, form: any): Observable<any> {
    const Data = form.value;
    const localbusiness = Data.business_id;
    const endpoint = `businesses/${localbusiness}/sync_pos`;
    return this.Post(this.ReturnType, endpoint, Data);
  }

  syncPosbranch(id: string, form: any): Observable<any> {
    const Data = form.value;
    const endpoint = `businesses/${id}/sync_pos_branch`;
    return this.Post(this.ReturnType, endpoint, Data);
  }
  syncAllBranches(): Observable<any> {
    const endpoint = 'branches/partner/sync-all-foodics-branches';
    return this.Get(this.ReturnType, endpoint, '');
  }
  syncPosCategory(id: string, form: any): Observable<any> {
    const Data = form.value;
    const endpoint = `businesses/${id}/sync_pos_product_category`;
    return this.Post(this.ReturnType, endpoint, Data);
  }

  syncAllPosCategory(id: string): Observable<any> {
    const endpoint = `businesses/${id}/sync_all_pos_product_category`;
    return this.Get(this.ReturnType, endpoint, '');
  }



  syncFoodics(type: string): Observable<any> {
    const data = {
      integration_type: type
    };
    const endpoint = 'businesses/foodics-auth-url';
    return this.Get(this.ReturnType, endpoint, data);
  }

  syncPosProdcut(id: string, form: any): Observable<any> {
    const Data = form.value;
    const endpoint = `businesses/${id}/sync_pos_product`;
    return this.Post(this.ReturnType, endpoint, Data);
  }

  syncAllPosProdcut(id: string): Observable<any> {
    const endpoint = `businesses/${id}/sync_all_pos_product`;
    return this.Get(this.ReturnType, endpoint, '');
  }

  updateProfile(form: UntypedFormGroup) {
    const Data = Helper.GetFormDataFromFormGroup(form);
    /** form.value because it not contain file or multipart */
    const endpoint = `${this.BaseEndPoint}profile`;
    return this.Put(this.ReturnType, endpoint, Data);
  }

  profileChangePassword(id: string, form: UntypedFormGroup) {
    /** form.value because it not contain file or multipart */
    const endpoint = `${this.BaseEndPoint}${id}/changePassword`;
    return this.Put(this.ReturnType, endpoint, form.value);
  }


  verifyAccount(form: any): Observable<any> {
    const Data = form.value;
    const endpoint = 'businesses/verify';
    return this.Post(this.ReturnType, endpoint, Data);
  }



  getBusinessData(id: string): Observable<any> {
    const endpoint = `businesses/${id}`;
    return this.Get(this.ReturnType, endpoint, '');
  }

  businessDetails(id: string): Observable<any> {
    return this.http.get(`${this.baseApiUrl}/${id}`).pipe(
      map((resp: any) => resp.data)
    );
  }
  logActivity() {
    const endpoint = 'businesses/log-activity';
    return this.Post(this.ReturnType, endpoint, new FormData());
  }
}