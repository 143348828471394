import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdminAuthService } from '@services/admin-auth.service';
import { SpinnerService } from '@app/services/spinner.service';
import { MobileWidthCheckerService } from '@services/mobile-width-checker.service'
import { animate, state, style, transition, trigger } from '@angular/animations';
import { tap } from 'rxjs';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  animations: [
    trigger('fadeOut', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('in => out', [
        animate('0.5s ease-out')
      ])
    ])
  ]
})
export class LayoutComponent implements OnInit {
  isSidebarOpen = true;
  isAuthenticated = false;
  currentLang: string = 'en';
  isMobileScreen$ = this.mobileWidthCheckerService.isMobileScreen$;
  loading = false;
  hideBanner = false;
  urlForOldBO: string = '';
  constructor(
    public translate: TranslateService,
    private adminService: AdminAuthService,
    private spinnerService: SpinnerService,
    private mobileWidthCheckerService: MobileWidthCheckerService
  ) { }
  ngOnInit() {
    this.currentLang = this.translate.getDefaultLang() || 'en';
    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = event.lang;
    });
    const user = JSON.parse(localStorage.getItem('User') as string);
    if (user) {
      this.isAuthenticated = true;
      const token = localStorage.getItem('JWT_TOKEN');
      const adminId = localStorage.getItem('adminId');
      if (token) {
        this.urlForOldBO = `https://partner.nugttah.com/redirect?log=n&accesstoken=${token}&id=${user.id}&image=${user.image_path}&brand=${user.brand}&adminId=${adminId}`;
      } else {
        console.warn('Token is missing from localStorage');
        this.urlForOldBO = '';
      }
    }
    const isAgent = localStorage.getItem('isAgent');
    this.adminService.isAuthenticated().then((res: any) => {
      if (res.IsLoggedIn && isAgent !== 'true') {
        this.checkShowNps();
      }
    });
    this.spinnerService.spinnerState$.subscribe((state: boolean) => {
      this.loading = state;
    });
  }
  checkShowNps() {
    // this.npsService.checkIfPartnerShouldRate().subscribe(
    //   (response: any) => {
    //     if (response.data.businessShouldRate) {
    //       this.showNps = true;
    //     }
    //   }
    // );
  }
  onSidebarStateChange(state: string) {
    setTimeout(() => {
      this.isSidebarOpen = (state === 'expanded');
    }, 250);
  }
}