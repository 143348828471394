// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-container {
  display: flex;
  align-items: flex-start;
  position: relative;
  font-family: "SF Pro Display";
}
.sidebar-container ::ng-deep .p-sidebar .p-sidebar-header {
  display: none !important;
}
.sidebar-container ::ng-deep .p-sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 24px;
  margin: auto;
  background-color: var(--primary-background-color);
}
.sidebar-container ::ng-deep .p-accordion .p-accordion-header .p-accordion-header-link {
  height: 48px;
  border-radius: 8px;
  padding: 10px 12px;
  border: none;
  background-color: var(--primary-background-color);
  color: var(--secondary-color);
}
.sidebar-container ::ng-deep .p-accordion p-accordiontab .p-accordion-tab {
  border-bottom: none !important;
}
.sidebar-container ::ng-deep .p-accordion .p-accordion-header .p-accordion-header-link:hover {
  background-color: var(--bg-img-small-sidebar-hover);
  color: var(--primary-color);
}
.sidebar-container ::ng-deep .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background-color: var(--bg-img-small-sidebar-hover);
  color: var(--primary-color);
}
.sidebar-container ::ng-deep .p-accordion .p-accordion-header .p-accordion-header-link:hover span,
.sidebar-container ::ng-deep .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link span {
  font-weight: 600;
}
.sidebar-container ::ng-deep .p-accordion .p-accordion-header .p-accordion-header-link:hover span {
  color: var(--primary-color);
}
.sidebar-container ::ng-deep .p-component {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.sidebar-container .nugttah-logo {
  padding-bottom: 44px;
}
.sidebar-container .card span {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.sidebar-container .sub-item-section .sub-item-label {
  padding: 10px;
  border-radius: 8px;
  color: var(--secondary-color);
  line-height: 16px;
  font-weight: 400;
  margin: 10px 4px;
  width: 100%;
}
.sidebar-container .sub-item-section .sub-item-label:hover {
  background-color: var(--bg-img-small-sidebar-hover);
  color: var(--primary-color);
  font-weight: 400;
  cursor: pointer;
}
.sidebar-container ::ng-deep .p-accordion .p-accordion-content {
  border: none;
  padding-right: 2px !important;
  background-color: var(--primary-background-color);
}
.sidebar-container .w-16rem {
  width: 256px !important;
  padding: 24px 24px 23px 24px;
}
.sidebar-container ::ng-deep .p-sidebar {
  border: 0px 1px 0px 0px !important;
  border-color: var(--primary-background-color) !important;
  transition: width 300ms cubic-bezier(0.86, 0, 0.07, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--primary-background-color);
}
.sidebar-container .sidebar-toggle {
  position: relative;
  transition: margin-left 400ms cubic-bezier(0.86, 0, 0.07, 1);
  cursor: pointer;
}
.sidebar-container .sidebar-toggle ::ng-deep .p-button {
  background-color: var(--primary-background-color);
  width: 64px;
  height: 64px;
  position: absolute;
  top: 32px;
  padding: 10px;
  display: flex;
  justify-content: center;
  border: 1px;
  border-radius: 0px 12px 12px 0px;
}
.sidebar-container .sidebar-toggle ::ng-deep .p-button .button-image {
  width: 240px;
  height: 24px;
}
.sidebar-container .sidebar-toggle ::ng-deep .p-button:focus {
  border: none;
  outline: none !important;
  box-shadow: none !important;
}
.sidebar-container .arrow-img {
  width: 16px;
  height: 16px;
}
.sidebar-container .footer .footer-image {
  height: 48px;
  border-radius: 8px;
  padding: 10px 12px;
  border: none;
  background-color: var(--primary-background-color);
}
.sidebar-container .footer .footer-image ::ng-deep .p-inputswitch.p-focus .p-inputswitch-slider {
  outline: none !important;
  outline-offset: 0px !important;
  box-shadow: none;
}
.sidebar-container .footer .footer-image ::ng-deep .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #0D425F;
}
.sidebar-container .footer .footer-image img {
  width: 24px;
  height: 24px;
}
.sidebar-container .footer .footer-image .sidebar-toole {
  color: var(--secondary-color);
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.sidebar-container .footer .footer-image:hover {
  background-color: var(--sidebar-bg-hover);
  cursor: pointer;
}
.sidebar-container .footer .sm-footer-image img {
  margin: 0px !important;
}
.sidebar-container .footer .sm-footer-image:hover {
  background-color: var(--bg-img-small-sidebar-hover);
}
.sidebar-container .footer .footer-image:hover .sidebar-toole {
  color: var(--primary-color) !important;
  font-weight: 600;
}
.sidebar-container .w-7rem {
  width: 112px !important;
}
.sidebar-container .collapsed-links-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.sidebar-container .collapsed-links-section .collapsed-images {
  width: 64px;
  height: 64px;
  padding: 16px;
  border-radius: 16px;
}
.sidebar-container .collapsed-links-section .collapsed-images img {
  width: 32px;
  height: 32px;
}
.sidebar-container .collapsed-links-section .collapsed-images:hover {
  background-color: var(--bg-img-small-sidebar-hover);
  color: var(--primary-text-color);
  font-weight: 600;
}
.sidebar-container .collapsed-links-section .active-sidebar-page {
  background-color: var(--bg-img-small-sidebar-hover);
}
.sidebar-container .collapsed-links-section .footer .footer-image {
  padding: 16px;
  border-radius: 16px;
  width: 64px;
  height: 64px;
}
.sidebar-container .collapsed-links-section .footer .footer-image img {
  width: 32px;
  height: 32px;
}
.sidebar-container .tooltip-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}
.sidebar-container .tooltip-container .tooltip {
  width: 200px;
  background-color: var(--primary-background-color);
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  z-index: 1000;
  pointer-events: auto;
  box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.07), 0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.05), 0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.04), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.04), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.03), 0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.02);
  border-radius: 0px 12px 12px 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.sidebar-container .tooltip-container .dropdown-sub-item-label {
  cursor: pointer;
  color: var(--secondary-color);
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.sidebar-container .tooltip-container .dropdown-sub-item-label p {
  padding: 8px 12px;
  border-radius: 8px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.sidebar-container .tooltip-container .dropdown-sub-item-label p:hover {
  color: var(--sidebar-dropdown-color);
  background-color: var(--sidebar-bg-hover);
}
.sidebar-container .selected-sub-item {
  background-color: var(--bg-img-small-sidebar-hover);
  color: var(--primary-color) !important;
  font-weight: 400;
  cursor: pointer;
}

.custom-sidebar-scroll ::ng-deep .p-sidebar-content {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary-background-color) transparent;
}

@media (max-width: 768px) {
  .sidebar-container .mobile-sidebar-toggle {
    width: 24px;
    position: absolute;
    top: 22px;
    left: 15px;
    right: 15px;
  }
}
.disabled {
  position: relative;
  pointer-events: none;
  color: #b0b0b0;
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.05);
}

.disabled::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Material Icons";
  content: "\\e897";
  color: var(--fourth-color);
  font-size: 1.5rem;
  z-index: 1;
  opacity: 0.8;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
