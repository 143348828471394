// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p-progressSpinner {
  position: absolute;
  z-index: 9999;
  background-color: #ffffff;
  opacity: 0.5;
  text-align: center;
  padding-top: 200px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.banner-section {
  height: 50px;
  padding: 10px 0px;
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0%;
  z-index: 999999999999;
  width: 100%;
  box-shadow: 0 8px 10px rgba(24, 23, 23, 0.37);
}
.banner-section .banner-text {
  color: #fff;
  text-align: center;
  flex-grow: 1;
}
.banner-section .banner-link {
  color: #90DEC1;
  font-weight: bold;
}
.banner-section .close-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 20px;
  margin-left: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
