export const environment = {
  production: true,
  environmentLabel: 'production',
  CARD_NUMBER_SPACE: '50',
  API_URL: 'https://api.nugttah.com/api/',
  API_WISER_URL: 'https://wiser.nugttah.com/api/v1/',
  // API_URL: 'https://test-api.nugttah.com/api/',
  // API_WISER_URL: 'https://test-wiser.nugttah.com/api/v1/',
  bootIntercom: true,
  sentryDsn: 'https://a660a27ea7594d77a05730276f9f4e08@o489538.ingest.sentry.io/5551936',
  version: '1.9cbc5de'
};