import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAuthGuard } from './services/Gaurds/AdminAuth.Gaurd';
import { NonAuthGuard } from './services/Gaurds/NonAdminAuth.Gaurd';
import { LoyaltyOrFreemiumAuthGuard } from './services/Gaurds/LoyaltyOrFreemiumAuth.Guard';
import { LoyaltyAuthGuard } from './services/Gaurds/LoyaltyAuth.Guard';
import { AnyPackageAuthGuard } from './services/Gaurds/AnyPackageAuth.Guard';
import { AnalyticsAuthGuard } from './services/Gaurds/AnalyticsAuth.Guard';
import { ArchiAuthGuard } from './services/Gaurds/ArchiAuthGuard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'statistics',
    pathMatch: 'full'
  },
  {
    path: 'statistics',
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AdminAuthGuard, AnalyticsAuthGuard],
    data: {
      breadcrumb: 'Dashboard'
    }
  },
  {
    path: 'brands',
    loadChildren: () => import('./features/brand/brand.module').then(m => m.BrandModule),
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Brand'
    }
  },
  {
    path: 'branches',
    loadChildren: () => import('./features/branches/branches.module').then(m => m.BranchesModule),
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Branches'
    }
  },
  {
    path: 'cashiers',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./features/cashiers/cashiers.module').then(m => m.CashiersModule),
    data: {
      breadcrumb: 'Cashiers'
    }
  },
  {
    path: 'login',
    loadChildren: () => import('./features/auth/login/login.module').then(m => m.LoginModule),
    canActivate: [NonAuthGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./features/auth/signup/signup.module').then(m => m.SignupModule),
    canActivate: [NonAuthGuard]
  },
  {
    path: 'redirect',
    loadChildren: () =>
      import('./features/redirect-from-admin/redirect-from-admin.module').then(m => m.RedirectFromAdminModule)
  },
  {
    path: 'sync-foodics',
    loadChildren: () => import('./features/sync/sync-foodics/sync-foodics.module').then(m => m.SyncFoodicsModule),
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Sync Foodics'
    }
  },
  {
    path: 'sync-marn',
    loadChildren: () => import('./features/sync/sync-marn/sync-marn.module').then(m => m.SyncMarnModule),
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Sync Marn'
    }
  },
  {
    path: 'sync-retm',
    loadChildren: () => import('./features/sync/sync-retm/sync-retm.module').then(m => m.SyncRetmModule),
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Sync Retm'
    }
  },
  {
    path: 'gift-card',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./features/gift-cards/gift-cards.module').then(m => m.GiftCardsModule),
    data: {
      breadcrumb: 'Gift card'
    }
  },
  {
    path: 'payouts',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./features/payouts/payouts.module').then(m => m.PayoutsModule),
    data: {
      breadcrumb: 'Payouts'
    }
  },
  {
    path: 'payment-invoices',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./features/payment-invoices/payment-invoices.module').then(m => m.PaymentInvoicesModule),
    data: {
      breadcrumb: 'Payment Invoices'
    }
  },
  {
    path: 'product-category',
    canActivate: [AdminAuthGuard],
    loadChildren: () =>
      import('./features/product-category/product-category.module').then(
        m => m.ProductCategoryModule
      ),
    data: {
      breadcrumb: 'Product Category'
    }
  },
  {
    path: 'products',
    canActivate: [AdminAuthGuard],
    loadChildren: () =>
      import('./features/product/product.module').then(
        m => m.ProductModule
      ),
    data: {
      breadcrumb: 'Products'
    }
  },
  {
    path: 'points-rules',
    canActivate: [AdminAuthGuard, LoyaltyOrFreemiumAuthGuard],
    loadChildren: () =>
      import('./features/points-rules/points-rules.module').then(m => m.PointsRulesModule),
    data: {
      breadcrumb: 'Points Rules'
    }
  },
  {
    path: 'sync-odoo',
    canActivate: [AdminAuthGuard],
    loadChildren: () =>
      import('./features/sync-odoo/sync-odoo.module').then(m => m.SyncOdooModule),
    data: {
      breadcrumb: 'Sync Odoo'
    }
  },
  {
    path: 'rewards',
    canActivate: [AdminAuthGuard, LoyaltyOrFreemiumAuthGuard],
    loadChildren: () => import('./features/points-rewards/points-rewards.module').then(m => m.PointsRewardsModule),
    data: {
      breadcrumb: 'Rewards'
    }
  },
  {
    path: 'stamps',
    canActivate: [AdminAuthGuard, LoyaltyOrFreemiumAuthGuard],
    loadChildren: () => import('./features/stamps/stamps.module').then(m => m.StampsModule),
    data: {
      breadcrumb: 'Stamps'
    }
  },
  {
    path: 'apple-wallet',
    canActivate: [AdminAuthGuard, LoyaltyAuthGuard],
    data: {
      breadcrumb: 'Add Wallet Notification'
    },
    loadChildren: () =>
      import('./features/apple-wallet/apple-wallet.module').then(m => m.AppleWalletModule)
  },
  {
    path: 'orders',
    canActivate: [AdminAuthGuard, AnyPackageAuthGuard],
    loadChildren: () => import('./features/reports/orders/orders.module').then(m => m.OrdersModule),
    data: {
      breadcrumb: 'Orders'
    }
  },
  {
    path: 'customers',
    canActivate: [AdminAuthGuard, AnyPackageAuthGuard],
    loadChildren: () => import('./features/reports/customers/customers.module').then(m => m.CustomersModule),
    data: {
      breadcrumb: 'Customers Review'
    }
  },
  {
    path: 'franchise',
    canActivate: [AdminAuthGuard, AnalyticsAuthGuard],
    loadChildren: () => import('./features/reports/franchise/franchise.module').then(m => m.FranchiseModule),
    data: {
      breadcrumb: 'Franchise'
    }
  },
  {
    path: 'pickup-transactions',
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Pickup Transactions'
    },
    loadChildren: () =>
      import('./features/reports/orders-by-nugttah/orders-by-nugttah.module').then(m => m.OrdersByNugttahModule)
  },
  {
    path: 'tableorders',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./features/reports/tables-orders/tables-orders.module').then(m => m.TablesOrdersModule),
    data: {
      breadcrumb: 'Table Orders'
    }
  },
  {
    path: 'smslist',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./features/reports/sms/sms.module').then(m => m.SmsModule),
    data: {
      breadcrumb: 'SMS List'
    }
  },
  {
    path: 'vouchers',
    canActivate: [AdminAuthGuard, AnalyticsAuthGuard],
    loadChildren: () => import('./features/reports/vouchers/vouchers.module').then(m => m.VouchersModule),
    data: {
      breadcrumb: 'Vouchers'
    }
  },
  {
    path: 'utilization',
    canActivate: [AdminAuthGuard, ArchiAuthGuard],
    loadChildren: () =>
      import('./features/utilization/utilization.module').then(m => m.UtilizationModule),
    data: {
      breadcrumb: 'Utilization'
    }
  },
  {
    path: 'profile',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule),
    data: {
      breadcrumb: 'Profile'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }