export const voucherStatuses = [
  { label: 'Used', value: 'used' },
  { label: 'Unused', value: 'unused' },
  { label: 'Expired', value: 'expired' }
];

export const voucherTypes = [
  { label: 'Stamp', value: 'stamp' },
  { label: 'Free', value: 'FREE' },
  { label: 'Reward Manual', value: 'reward_manual' },
  { label: 'Stamp Manual', value: 'stamp_manual' },
  { label: 'Reward', value: 'reward' }
];

export const voucherManualTypes = [
  { label: 'Reward Manual', value: 'reward_manual' },
  { label: 'Stamp Manual', value: 'stamp_manual' }
];